import qs from 'qs';

import { defaultTokenData, defaultSSOTokenData } from 'models/auth';

import storage from './storage';

const { API_ENDPOINT } = process.env;

const getToken = () => {
	const tokenData = storage.getItem('token');

	return tokenData === null ? defaultTokenData : JSON.parse(tokenData);
};

const getSSOToken = () => {
	const tokenData = storage.getItem('bs-token');

	return tokenData === null ? defaultSSOTokenData : JSON.parse(tokenData);
};

export const generateUrl = (url, params) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets', encode: encodeURI });

	const URL =
		paramsString !== ''
			? `${API_ENDPOINT}/backstage/api/v1/${url}?${paramsString}`
			: `${API_ENDPOINT}/backstage/api/v1/${url}`;

	return URL;
};

export const wrapFetch = async (url, options = { headers: {} }, params = {}) => {
	const URL = generateUrl(url, params);

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...options.headers,
	});

	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

export const wrapAuthFetch = async (url, options = { headers: {} }, params = {}) => {
	const token = getToken();

	return wrapFetch(
		url,
		{
			...options,
			headers: {
				Authorization: `Bearer ${token.access_token}`,
				...options.headers,
			},
		},
		params,
	);
};

export const wrapFetchFormData = async (url, options, params = {}) => {
	const URL = generateUrl(url, params);

	const result = await fetch(URL, options);

	return result.json();
};

export const wrapAuthFetchFormData = async (url, options, params = {}) => {
	const token = getToken();

	return wrapFetchFormData(
		url,
		{
			...options,
			headers: {
				Authorization: `Bearer ${token.access_token}`,
				...options.headers,
			},
		},
		params,
	);
};

export const apiUrl = (url, params) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL =
		paramsString !== ''
			? `${API_ENDPOINT}/backstage/api/${url}?${paramsString}`
			: `${API_ENDPOINT}/backstage/api/${url}`;

	return URL;
};

export const wrapFetchSSO = async (url, options = { headers: {} }, params = {}) => {
	const URL = apiUrl(url, params);

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...options.headers,
	});

	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

export const wrapFetchFormDataSSO = async (url, options, params = {}) => {
	const URL = apiUrl(url, params);

	const result = await fetch(URL, options);

	return result.json();
};

export const wrapSSOFetch = async (url, options = { headers: {} }, params = {}) => {
	const token = getSSOToken();

	return wrapFetchSSO(
		url,
		{
			...options,
			headers: {
				Authorization: `Bearer ${token.access_token}`,
				...options.headers,
			},
		},
		params,
	);
};

export const wrapSSOFetchFormData = async (url, options, params = {}) => {
	const token = getSSOToken();

	return wrapFetchFormDataSSO(
		url,
		{
			...options,
			headers: {
				Authorization: `Bearer ${token.access_token}`,
				...options.headers,
			},
		},
		params,
	);
};
